<template>
	<div id="rose-chart">
		<div class="rose-chart-title">商户门类销售占比</div>
		<dv-charts :option="option" />
	</div>
</template>

<script>
import { amountByCategory } from '@/api'

export default {
	name: 'RoseChart',
	data() {
		return {
			option: {
				title: {
					text: '商户门类销售占比'
				},
				series: [
					{
						type: 'pie',
						radius: '50%',
						// roseSort: false,
						data: [
							// { name: '肉品', value: randomExtend(40, 70) },
							// { name: '蔬菜', value: randomExtend(20, 30) },
							// { name: '水产', value: randomExtend(10, 50) },
							// { name: '副食', value: randomExtend(40, 50) },
							// { name: '其他', value: randomExtend(20, 30) },
						],
						insideLabel: {
							show: true
						},
						outsideLabel: {
							formatter: '{name} {percent}%',
							labelLineEndLength: 20,
							style: {
								fill: '#fff'
							},
							labelLineStyle: {
								stroke: '#fff'
							}
						},
						roseType: false
					}
				],
				color: [
					'#FFFF99',
					'#B5FF91',
					'#94DBFF',
					'#FFBAFF',
					'#FFBD9D',
					'#C7A3ED',
					'#CC9898',
					'#8AC007',
					'#CCC007',
					'#FFAD5C'
				]
			}
		}
	},
	methods: {
		createData() {
			// const { randomExtend } = this

			amountByCategory().then((res) => {
				console.log(res.data.data)
				this.option.series[0].data = res.data.data.map((item) => {
					return { name: item.categoryLabel, value: item.amount }
				})
				this.option = { ...this.option }
			})
		},
		randomExtend(minNum, maxNum) {
			if (arguments.length === 1) {
				return parseInt(Math.random() * minNum + 1, 10)
			} else {
				return parseInt(
					Math.random() * (maxNum - minNum + 1) + minNum,
					10
				)
			}
		}
	},
	mounted() {
		const { createData } = this

		createData()

		setInterval(createData, 30000)
	}
}
</script>

<style lang="less">
#rose-chart {
	width: 30%;
	height: 100%;
	background-color: rgba(6, 30, 93, 0.5);
	border-top: 2px solid rgba(1, 153, 209, 0.5);
	box-sizing: border-box;

	.rose-chart-title {
		height: 50px;
		font-weight: bold;
		text-indent: 20px;
		font-size: 20px;
		display: flex;
		align-items: center;
	}

	.dv-charts-container {
		height: calc(~'100% - 50px');
	}
}
</style>
