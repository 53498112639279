import request from '@/util/http'

// 交易额和交易笔数
export function getMerchant(fromTime = null, toTime = null) {
	let url = `/datav/summary?`
	if (fromTime) url += `&fromTime=${fromTime}`
	if (toTime) url += `&toTime=${toTime}`
	return request({
		method: 'GET',
		url
	})
}

export function getTest() {
	return request({
		method: 'GET',
		url: '/datav/test/summary'
	})
}

export function getMarket() {
	return request({
		method: 'GET',
		url: '/datav/market/summary'
	})
}

export function getTrade() {
	return request({
		method: 'GET',
		url: '/datav/trade/numberByDay'
	})
}

export function topNumber() {
	return request({
		method: 'GET',
		url: '/datav/top5/number'
	})
}
export function topAmount() {
	return request({
		method: 'GET',
		url: '/datav/top5/amount'
	})
}

export function tradeSummary() {
	return request({
		method: 'GET',
		url: '/datav/trade/summary'
	})
}

export function amountByCategory() {
	return request({
		method: 'GET',
		url: '/datav/amountByCategory'
	})
}
