<template>
  <div id="cards">
    <dv-charts :option="option" />
  </div>
</template>

<script>
import { getTrade } from '@/api'

export default {
  name: 'Cards',
  data() {
    return {
      cards: [],
      option: {
        title: {
          text: '每日销售情况',
          style: {
            fill: '#fff'
          }
        },
        xAxis: {
          name: '日期',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          nameTextStyle: {
            fill: '#fff',
            fontSize: 10
          },
          axisLine: {
            style: {
              stroke: '#fff' // 设置x轴线颜色
            }
          },
          axisLabel: {
            style: {
              fill: '#fff',
              fontSize: 10,
              rotate: 0
            }
          }
        },
        yAxis: [{

          name: '交易笔数',
          data: 'value',
          axisLine: {
            lineStyle: {
              color: '#fff' // 设置x轴线颜色
            }
          }
        },
        {
          name: '交易额',
          data: 'value',
          position: 'right',
          // max: 2000,
          splitLine: {
            show: false
          }
        }
        ],
        series: [
          {
            name: '交易笔数',
            data: [1200, 2230],
            type: 'line',
            lineArea: {
              show: true,
              gradient: ['rgba(55, 162, 218, 0.6)', 'rgba(55, 162, 218, 0)']
            }
          },
          {
            name: '交易额',
            data: [1000, 1200, 900, 1500, 900, 1200, 1000],
            type: 'line',
            smooth: true,
            lineArea: {
              show: true,
              gradient: ['rgba(251, 114, 147, 1)', 'rgba(251, 114, 147, 0)']
            },
            lineStyle: {
              stroke: 'rgba(251, 114, 147, 1)',
              lineDash: [3, 3]
            },
            linePoint: {
              style: {
                stroke: 'rgba(251, 114, 147, 1)'
              }
            },
            yAxisIndex: 1
          }
        ]
      }
    }
  },
  methods: {
    createData() {
      getTrade().then((res) => {
        console.log(res.data.data)
        this.option.xAxis.data = res.data.data.map((item) => {
          return item.day
        })
        this.option.series[0].data = res.data.data.map((item) => {
          return item.num
        })
        this.option.series[1].data = res.data.data.map((item) => {
          return item.amount
        })
        this.option = { ...this.option }
      })
    }
  },
  mounted() {
    this.createData()
    setInterval(this.createData, 30000)
  }
}
</script>

<style lang="less">
#cards {
  display: flex;
  justify-content: space-between;
  height: 45%;

  .card-item {
    background-color: rgba(6, 30, 93, 0.5);
    border-top: 2px solid rgba(1, 153, 209, .5);
    width: 19%;
    display: flex;
    flex-direction: column;
  }

  .card-header {
    display: flex;
    height: 20%;
    align-items: center;
    justify-content: space-between;

    .card-header-left {
      font-size: 18px;
      font-weight: bold;
      padding-left: 20px;
    }

    .card-header-right {
      padding-right: 20px;
      font-size: 40px;
      color: #03d3ec;
    }
  }

  .card-footer {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .card-footer-item {
    padding: 5px 10px 0px 10px;
    box-sizing: border-box;
    width: 40%;
    background-color: rgba(6, 30, 93, 0.7);
    border-radius: 3px;

    .footer-title {
      font-size: 15px;
      margin-bottom: 5px;
    }

    .footer-detail {
      font-size: 20px;
      color: #1294fb;
      display: flex;
      font-size: 18px;
      align-items: center;

      .dv-digital-flop {
        margin-right: 5px;
      }
    }
  }
}
</style>
