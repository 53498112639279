<template>
  <div id="scroll-board">
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>
import { tradeSummary } from '@/api'

export default {
  name: 'ScrollBoard',
  data () {
    return {
      config: {
        header: ['日期', '星期', '交易金额', '交易笔数', '客单价'],
        data: [],
        index: true,
        columnWidth: [50, 170],
        align: ['center'],
        rowNum: 7,
        headerBGC: '#1981f6',
        headerHeight: 45,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      tradeSummary().then((res) => {
        console.log(res.data.data)
        this.config.data = res.data.data.map((item) => {
          return [
            item.day,
            item.weekday,
            item.amount,
            item.num,
            item.avg
          ]
        })
        this.config = { ...this.config }
      })
    }
  }
}
</script>

<style lang="less">
#scroll-board {
  width: 50%;
  box-sizing: border-box;
  margin-left: 20px;
  height: 100%;
  overflow: hidden;
}
</style>
