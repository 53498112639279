<template>
	<div id="digital-flop">
		<div
			class="digital-flop-item"
			v-for="item in digitalFlopData"
			:key="item.title"
		>
			<div class="digital-flop-title">{{ item.title }}</div>
			<div class="digital-flop">
				<dv-digital-flop
					:config="item.number"
					style="width: 100px; height: 50px"
				/>
				<div class="unit">{{ item.unit }}</div>
			</div>
		</div>

		<!-- <dv-decoration-10 /> -->
	</div>
</template>

<script>
import { getMerchant } from '@/api'

export default {
	name: 'DigitalFlop',
	props: ['fromTime', 'toTime'],
	data() {
		return {
			digitalFlopData: []
		}
	},
	methods: {
		createData() {
			getMerchant(this.fromTime, this.toTime).then((res) => {
				this.digitalFlopData = [
					{
						title: '设备总数',
						number: {
							number: [res.data.data.deviceTotal],
							content: '{nt}',
							textAlign: 'right',
							style: {
								fill: '#4d99fc',
								fontWeight: 'bold'
							}
						},
						unit: '台'
					},
					{
						title: '商户总数',
						number: {
							number: [res.data.data.merchantTotal],
							content: '{nt}',
							textAlign: 'right',
							style: {
								fill: '#f46827',
								fontWeight: 'bold'
							}
						},
						unit: '个'
					},
					{
						title: '总交易额',
						number: {
							number: [res.data.data.orderTotalAmount / 10000],
							content: '{nt}',
							textAlign: 'right',
							toFixed: 2,
							style: {
								fill: '#40faee',
								fontWeight: 'bold'
							}
						},
						unit: '万元'
					},
					{
						title: '总交易笔数',
						number: {
							number: [res.data.data.orderTotalNumber],
							content: '{nt}',
							textAlign: 'right',
							style: {
								fill: '#4d99fc',
								fontWeight: 'bold'
							}
						},
						unit: '笔'
					},
					{
						title: '市场总检测数',
						number: {
							number: [res.data.data.testTotalNumber],
							content: '{nt}',
							textAlign: 'right',
							style: {
								fill: '#4d99fc',
								fontWeight: 'bold'
							}
						},
						unit: '次'
					},
					{
						title: '今日进人数',
						number: {
							number: [res.data.data.keliu.total.in],
							content: '{nt}',
							textAlign: 'right',
							style: {
								fill: '#4d99fc',
								fontWeight: 'bold'
							}
						},
						unit: '人'
					},
					{
						title: '今日出人数',
						number: {
							number: [res.data.data.keliu.total.out],
							content: '{nt}',
							textAlign: 'right',
							style: {
								fill: '#4d99fc',
								fontWeight: 'bold'
							}
						},
						unit: '人'
					}
				]
			})
		}
	},
	mounted() {
		const { createData } = this

		createData()

		setInterval(createData, 30000)
	}
}
</script>

<style lang="less">
#digital-flop {
	position: relative;
	height: 15%;
	flex-shrink: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: rgba(6, 30, 93, 0.5);

	.dv-decoration-10 {
		position: absolute;
		width: 95%;
		left: 2.5%;
		height: 5px;
		bottom: 0px;
	}

	.digital-flop-item {
		width: 11%;
		height: 80%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-left: 3px solid rgb(6, 30, 93);
		border-right: 3px solid rgb(6, 30, 93);
	}

	.digital-flop-title {
		font-size: 20px;
		margin-bottom: 20px;
	}

	.digital-flop {
		display: flex;
	}

	.unit {
		margin-left: 10px;
		display: flex;
		align-items: flex-end;
		box-sizing: border-box;
		padding-bottom: 13px;
	}
}
</style>
